import { useAppState } from "./state";

function MuteCrowdCheckbox() {
    const { setMuteCrowd } = useAppState(state => state.actions);
    const { muteCrowd } = useAppState(state => state.values);

    const onChange = (ev: React.FormEvent<HTMLInputElement>) => {
        const { checked } = ev.currentTarget;
        setMuteCrowd(checked);
    };

    return (
        <input type="checkbox"
            onChange={onChange}
            checked={muteCrowd}
            style={{height: "1em"}}
        />
    )
}

export default MuteCrowdCheckbox;