import MediaDeviceSelectors from "./MediaDeviceSelectors";
import MuteMeCheckbox from "./MuteMeCheckbox";
import MyAudioVis from "./MyAudioVis";

export default function MediaPanel() {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }}>
        <MyAudioVis />
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "1em",
        }}>
            <MuteMeCheckbox />
            <p><b>Mute me</b></p>
        </div>
        <MediaDeviceSelectors />
    </div>
}