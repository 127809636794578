// import chroma from 'chroma-js';

import { useCallback, useEffect, useRef, useState } from "react";

interface InnerProps {
    level: number
    label: string
}

function AudioLevelMeterInner(props: InnerProps) {
    const levelPcnt = `${Math.round(100 * props.level)}%`;

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "10px", marginRight: "10px", width: "25px" }}>
            <div style={{ height: "100px", width: "10px", display: "flex", flexDirection: "column-reverse", border: "1px solid black" }}>
                <div style={{ width: "100%", height: levelPcnt, backgroundColor: "red" }} />
            </div>
            <p style={{ marginTop: "5px" }}>{props.label}</p>
        </div>
    );
}

interface Props {
    analyser: AnalyserNode;
    label: string;
    disabled?: boolean;
}

function AudioLevelMeter(props: Props) {
    // const scale = chroma.scale(['green', 'yellow', 'red']);
    // return scale;

    const [level, setLevel] = useState<number>();

    const requestRef = useRef<number>();
    const previousTimeRef = useRef<number>();
    const timeoutRef = useRef<NodeJS.Timeout>();

    const animate: FrameRequestCallback = useCallback(time => {
        if (previousTimeRef.current !== undefined) {
            // const deltaTime = time - previousTimeRef.current;
            // TODO: use deltaTime somehow?

            if (props.disabled) {
                if (level !== 0) {
                    setLevel(0);
                }
            } else {
                const bufferLength = props.analyser.fftSize;
                const data = new Float32Array(bufferLength);
                props.analyser.getFloatTimeDomainData(data);

                const toDecibels = (val: number) => 20 * Math.log(Math.abs(val));
                const avgDb = data.reduce((prev, next) => prev + toDecibels(next), 0) / data.length;

                const minDb = -100;
                const maxDb = 0;

                const level = (avgDb - minDb) / (maxDb - minDb);
                const clamped = Math.min(Math.max(level, 0), 1)

                // Pass on a function to the setter of the state
                // to make sure we always have the latest state
                setLevel(clamped);
            }
        }
        previousTimeRef.current = time;
        const delay = 50; // ms
        timeoutRef.current = setTimeout(() => {
            requestRef.current = requestAnimationFrame(animate);
        }, delay);
    }, [props.analyser, props.disabled, level]);

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => {
            // cancel next animation request
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }

            // don't request another animation
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }
    }, [animate]); // Make sure the effect runs only once


    if (level === undefined) {
        return <p>?</p>
    }

    return <AudioLevelMeterInner level={level} label={props.label} />
}

export default AudioLevelMeter;