import { LatencyResult } from 'wos-types/LatencyResult';

export enum ConnectionStatus {
  Connecting,
  Connected,
  Disconnected,
}

export enum LatencyStatus {
  Unset,
  Measuring,
  Calculating,
  Valid,
  Invalid,
}

export enum LatencyErrorKind {
  LowCorrelation,
  LatencyBounds,
  ServerError,
}

export type LatencyError = {
  kind: LatencyErrorKind,
  msg?: string,
}

export type LatencyState = {
  status: LatencyStatus.Unset
  | LatencyStatus.Measuring
  | LatencyStatus.Calculating;
} | {
  status: LatencyStatus.Valid;
  result: LatencyResult;
} | {
  status: LatencyStatus.Invalid;
  error: LatencyError
  result?: LatencyResult;
}