export default function LiveIndicator() {
    return <div style={{
        border: "2px solid white",
        backgroundColor: "red",
        height: "min-content",
        borderRadius: "6px",
    }}>
        <p style={{
            color: "white",
            marginLeft: "6px",
            marginRight: "6px",
            marginTop: "3px",
            marginBottom: "3px",
        }}><b>LIVE</b></p>
    </div>
}