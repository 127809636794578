export default function DonatePanel() {
    return <div style={{ margin: "auto", width: "fit-content" }}>
        <h3>Thank you for your support!</h3>
        {//@ts-ignore
            <stripe-buy-button
                buy-button-id="buy_btn_1NiYOvEMDlpMMJJotYHfvhDG"
                publishable-key="pk_live_51NiYB0EMDlpMMJJo2gqlslgBwPTLlIt9TW9f7YCZUdieVPmvkkSIhGWinXkbKDNGW5puFCMIEGtIC66lvyWMelbF00tGuJrDeT"
            />
        }
    </div>
}