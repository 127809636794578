
import { useAppState } from "./state";

function MuteMeCheckbox() {
    const { setMuteMe } = useAppState(state => state.actions);
    const { muteMe } = useAppState(state => state.values);

    const onChange = (ev: React.FormEvent<HTMLInputElement>) => {
        const { checked } = ev.currentTarget;
        setMuteMe(checked);
    };

    return (
        <input type="checkbox"
            onChange={onChange}
            checked={muteMe}
            style={{height: "30px", width: "30px"}}
        />
    )
}

export default MuteMeCheckbox;