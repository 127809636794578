import AudioLevelMeter from "./AudioLevelMeter";
import { useAppState } from "./state";

function MyAudioVis() {
    const audio = useAppState(state => state.values.audio);

    if (!audio) {
        return <p>Initializing audio...</p>
    }

    let sendEnabled = false;
    for (const sending of Array.from(audio.sendEnabled.values())) {
        if (sending) {
            sendEnabled = true;
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", margin: "20px", alignItems: "center" }}>
            <h2>My Audio</h2>
            <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
                <AudioLevelMeter label="me" analyser={audio.meAnalyser} />
                <AudioLevelMeter label="hear" analyser={audio.hearAnalyser} />
                <AudioLevelMeter label="send" analyser={audio.sendAnalyser} disabled={!sendEnabled} />
            </div>
        </div>
    );
}

export default MyAudioVis;