import { create } from "zustand";

import { MessageToUser } from 'wos-types/MessageToUser';
import { MessageFromUser } from 'wos-types/MessageFromUser';
import { ClientMessage } from 'wos-types/ClientMessage';
import { PeerDescription } from "wos-types/PeerDescription";
import { PeerMessage } from 'wos-types/PeerMessage';
import { UserId } from "wos-types/UserId";
import { PeerConnectionInfo, createPeerConnection, createPeerConnectionInfo, getMediaStream } from "./webrtc";
import { TwilioToken } from "wos-types/TwilioToken";
import { AudioGraphDescription } from "wos-types/AudioGraphDescription";
import { AudioGraphAdjacencyMatrix } from "wos-types/AudioGraphAdjacencyMatrix";
import { AudioPeerInstructions } from "wos-types/AudioPeerInstructions";
import { AudioGraphType } from "wos-types/AudioGraphType";
import { LinearStage } from "wos-types/LinearStage";
import { ConnectionStatus, LatencyErrorKind, LatencyState, LatencyStatus } from "./types";
import { LATENCY_CORR_THRESHOLD } from "./latency";
import { AudioSummary, FromWorkerMessage, FromWorkerMessageKind, ToWorkerMessage, ToWorkerMessageKind } from "./worker";
import { extractAudioData } from "./utils";
import { LatencyResult } from "wos-types/LatencyResult";

type StateSetter = (state: State) => Partial<State>;
type ValueSetter = (values: Values) => Partial<Values>;
type StateGetter = () => State;
// type ValueGetter = () => Values;

type SetFunc = (setter: ValueSetter) => void;

/// Create a StateSetter from a ValueSetter.
type Translator = (setValues: ValueSetter) => StateSetter;
const action: Translator = (setValues) => (oldState) => {
  const newValues: Values = {
    ...oldState.values,
    ...setValues(oldState.values),
  };

  // Expose state to browser console
  (window as any).state.values = newValues;

  return { values: newValues };
};

// // Combine multiple value setters
// const combineObjects = (a: Object, b: Object): Object => ({ ...a, ...b });
// const combineSetters =
//   (setters: ValueSetter[]): ValueSetter =>
//     (oldValues) =>
//       setters.map((fn) => fn(oldValues)).reduce(combineObjects, []);

interface AudioValues {
  context: AudioContext;
  myInput?: MediaStreamAudioSourceNode;
  peerInputs: Map<UserId, MediaStreamAudioSourceNode>;
  sendStreams: Map<UserId, MediaStreamAudioDestinationNode>;
  recordStream: MediaStreamAudioDestinationNode;
  userRecorder: MediaRecorder;
  latencyStream: MediaStreamAudioDestinationNode;
  latencyRecorder: MediaRecorder;
  meAnalyser: AnalyserNode;
  hearAnalyser: AnalyserNode;
  sendAnalyser: AnalyserNode;
  peerDelay: DelayNode;
  sendEnabled: Map<UserId, boolean>;
}

interface MediaDeviceSelections {
  audioInput?: string;
  audioOutput?: string;
  videoInput?: string;
}

interface Values {
  username?: string;
  ws?: WebSocket;
  userId?: bigint;
  peers: PeerDescription[];
  mediaStream?: MediaStream;
  peerConnections: Map<UserId, Promise<PeerConnectionInfo>>;
  peerConnectionStatuses: Map<UserId, ConnectionStatus>;
  remoteMediaStreams: Map<UserId, MediaStream>;
  twilioToken?: TwilioToken;
  audioGraphAdjMat?: AudioGraphAdjacencyMatrix;
  audio?: AudioValues;
  stage: PeerDescription[]
  stageEnabled: boolean;
  muteCrowd: boolean;
  muteMe: boolean;
  webSocketStatus: ConnectionStatus;
  reconnectTimer?: NodeJS.Timer;
  recordings: File[];
  nowRecording: boolean;
  latencyRecording: AudioBuffer | null;
  latency: LatencyState;
  mediaDevices: MediaDeviceSelections;
  readyToStream: boolean;
  latencyRecordingSummary: AudioSummary | null;
  worker?: Worker;
}

const initialValues: Values = {
  peers: [],
  peerConnections: new Map(),
  peerConnectionStatuses: new Map(),
  remoteMediaStreams: new Map(),
  stage: [],
  stageEnabled: false,
  muteCrowd: false,
  muteMe: false,
  webSocketStatus: ConnectionStatus.Disconnected,
  recordings: [],
  nowRecording: false,
  mediaDevices: {},
  readyToStream: false,
  latencyRecording: null,
  latency: { status: LatencyStatus.Unset },
  latencyRecordingSummary: null,
};

interface State {
  values: Values;
  actions: Actions;
}

const fetchLatency = async (set: SetFunc, recorded: AudioBuffer): Promise<LatencyResult> => {
  const audio = extractAudioData(recorded).data;
  const { sampleRate } = recorded;

  const baseUrl = "https://api.webofsong.com";
  const url = `${baseUrl}/latency?sr=${sampleRate}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: audio.buffer,
    });

    if (response.status !== 200) {
      let text = await response.text();
      throw new Error(text)
    }

    const result: LatencyResult = await response.json();
    return result;
  } catch (err) {

    // This is not necessarily true
    let typedErr = err as Error;

    set(() => ({
      latency: {
        status: LatencyStatus.Invalid,
        error: {
          kind: LatencyErrorKind.ServerError,
          msg: typedErr.message
        }
      }
    }));
    throw err;
  }
}

const handleLatencyResult = (set: SetFunc, get: StateGetter, result: LatencyResult) => {
  let latencyState: LatencyState = {
    status: LatencyStatus.Valid,
    result
  };

  // Check whether the correlation meets the threshold
  if (result.maxCorr < LATENCY_CORR_THRESHOLD) {
    latencyState = {
      status: LatencyStatus.Invalid,
      result,
      error: { kind: LatencyErrorKind.LowCorrelation },
    };
    // Make sure the latency is in-bounds
  } else if (result.millis < 0 || result.millis > 1000) {
    latencyState = {
      status: LatencyStatus.Invalid,
      result,
      error: { kind: LatencyErrorKind.LatencyBounds },
    };
  }

  // Update the value
  setLatency(get, set, latencyState);
}

const sendWorkerMsg = (worker: Worker | undefined, msg: ToWorkerMessage, transfers?: any[]) => {
  if (worker) {
    worker.postMessage({ worker: true, msg }, transfers || []);
  } else {
    console.error("Can't send worker message before init");
  }
};

const sendMessage = (ws: WebSocket | undefined, msg: MessageFromUser) => {
  if (ws && ws.readyState === ws.OPEN) {
    const encoded = JSON.stringify(msg);
    ws.send(encoded);
  } else {
    console.error("can't send message without WS!");
  }
}

const sendClientMessage = (ws: WebSocket | undefined, msg: ClientMessage) => {
  const wrapped: MessageFromUser = {
    to: "Server",
    ...msg
  };
  sendMessage(ws, wrapped);
}

const sendPeerMessage = (ws: WebSocket | undefined, msg: PeerMessage, peerId: UserId) => {
  const wrapped: MessageFromUser = {
    to: "Peer",
    peerId: peerId,
    msg
  };
  sendMessage(ws, wrapped);
}

const setUsername = async (set: SetFunc, username: string) => {
  set(() => ({ username }));
};

const setLatency = async (get: StateGetter, set: SetFunc, latency: LatencyState) => {
  const { audio } = get().values;
  if (!audio) {
    throw new Error("Cannot set latency before audio context init");
  }

  // Store value
  set(() => ({ latency }));

  // Update audio delay
  const isValid = (latency.status === LatencyStatus.Valid);
  const delayMillis = isValid ? latency.result.millis : 0;
  audio.peerDelay.delayTime.value = delayMillis / 1000;
};

const register = async (get: StateGetter) => {
  const { ws, username } = get().values;

  if (!username) {
    throw new Error("cannot register without username");
  }

  const msg: ClientMessage = {
    kind: "Register",
    username,
  };

  sendClientMessage(ws, msg);
};

const handleRegisterSuccess = (set: SetFunc, get: StateGetter, userId: UserId, token: TwilioToken) => {
  set(() => ({
    userId,
    twilioToken: token,
  }));

  // Get ready to stream.
  // Notify the server when connected to all peers
  // (or after 5-second timeout elapses)
  const timeoutMs = 5000;
  setTimeout(
    () => {
      const { readyToStream } = get().values;
      if (!readyToStream) {
        setReadyToStream(set);
        sendReadyToStream(get);
      }
    },
    timeoutMs
  );

  console.log('stored user id:', userId);
}

const setPeerConnectionStatus = (get: StateGetter, peerId: UserId, status: ConnectionStatus) => {
  const { peerConnectionStatuses } = get().values;
  peerConnectionStatuses.set(peerId, status);
}

const createPeerConnectionInfoPromise = async (connectionPromise: Promise<RTCPeerConnection>, readyNow: boolean): Promise<PeerConnectionInfo> => {
  const connection = await connectionPromise;
  const info = createPeerConnectionInfo(connection);
  if (readyNow) {
    info.setReadyForIceCandidates();
  }
  return info;
}

const greetPeer = async (get: StateGetter, peerId: UserId) => {
  const { ws, audio, peerConnections, mediaStream, twilioToken } = get().values;
  const { storeRemoteTrack } = get().actions;

  if (!audio) {
    throw new Error("audio context not initialized");
  }

  if (!mediaStream) {
    throw new Error('cannot initiate peer connections without media stream');
  }

  if (peerConnections.has(peerId)) {
    throw new Error('tried to create duplicate peer connection');
  }

  if (!twilioToken) {
    throw new Error('cannot initiate peer connections without twilio token');
  }

  // Each peer gets a send node in the audio graph
  let sendStream = audio.sendStreams.get(peerId);
  if (!sendStream) {
    sendStream = audio.context.createMediaStreamDestination();
    audio.sendStreams.set(peerId, sendStream);

    // Start out muted
    const sendTracks = sendStream.stream.getAudioTracks();
    for (const track of Array.from(sendTracks)) {
      track.enabled = false;
    }

    audio.sendAnalyser.connect(sendStream);
  }

  // create & store RTCPeerConnection
  const send = (msg: PeerMessage) => sendPeerMessage(ws, msg, peerId);
  const storeTrack = (track: MediaStreamTrack) => storeRemoteTrack(peerId, track);
  const setStatus = (status: ConnectionStatus) => setPeerConnectionStatus(get, peerId, status);
  const peerConnectionPromise = createPeerConnection(
    send,
    storeTrack,
    sendStream.stream,
    mediaStream,
    twilioToken,
    setStatus
  );
  const readyNow = false;
  const infoPromise = createPeerConnectionInfoPromise(peerConnectionPromise, readyNow);
  peerConnections.set(peerId, infoPromise);
  console.log('greeted peer', peerId);
}

const handleNewPeer = (get: StateGetter, peer: PeerDescription) => {
  console.log('got new peer:', peer);
  greetPeer(get, peer.userId);
}

const removeOldPeerConnections = (set: SetFunc, get: StateGetter) => {
  const { peers, peerConnections, audio, remoteMediaStreams } = get().values;
  const peerConnectionsCopy = new Map(peerConnections);
  const remoteMediaStreamsCopy = new Map(remoteMediaStreams);
  const activePeerIds = peers.map(peer => peer.userId);

  for (const peerId of Array.from(peerConnectionsCopy.keys())) {
    if (!activePeerIds.includes(peerId)) {
      peerConnectionsCopy.delete(peerId);
    }
  }

  for (const peerId of Array.from(remoteMediaStreamsCopy.keys())) {
    if (!activePeerIds.includes(peerId)) {
      remoteMediaStreamsCopy.delete(peerId);
    }
  }

  if (audio) {
    for (const peerId of Array.from(audio.peerInputs.keys())) {
      if (!activePeerIds.includes(peerId)) {
        audio.peerInputs.delete(peerId);
      }
    }
  }

  set(() => ({ peerConnections: peerConnectionsCopy, remoteMediaStreams: remoteMediaStreamsCopy }));
}

const handlePeerHello = (set: SetFunc, peerId: UserId) => {
  console.log('got hello from peer', peerId);
}

const handlePeerOffer = async (get: StateGetter, peerId: UserId, offer: RTCSessionDescription) => {
  const { ws, peerConnections, audio, mediaStream, twilioToken } = get().values;
  const { storeRemoteTrack, checkReadyToStream } = get().actions;

  if (!audio) {
    throw new Error("audio context not initialized");
  }

  if (!mediaStream) {
    throw new Error('cannot initiate peer connections without media stream');
  }

  if (!twilioToken) {
    throw new Error('cannot initiate peer connections without twilio token');
  }

  // Each peer gets a send node in the audio graph
  let sendStream = audio.sendStreams.get(peerId);
  if (!sendStream) {
    sendStream = audio.context.createMediaStreamDestination();
    audio.sendStreams.set(peerId, sendStream);

    // Start out muted
    const sendTracks = sendStream.stream.getAudioTracks();
    for (const track of Array.from(sendTracks)) {
      track.enabled = false;
    }

    audio.sendAnalyser.connect(sendStream);
  }

  let peerConnectionInfoPromise = peerConnections.get(peerId);
  if (peerConnectionInfoPromise) {
    // If we already have a connection, then this is an answer to our offer
    if (offer.type !== "answer") {
      throw new Error("expected session description to be an answer");
    }
    const peerConnectionInfo = await peerConnectionInfoPromise;
    const peerConnection = peerConnectionInfo.connection;
    await peerConnection.setRemoteDescription(offer);
    peerConnectionInfo.setReadyForIceCandidates();
    console.log('SET REMOTE DESCRIPTION', offer);
  } else {
    // Otherwise, this is a new offer
    if (offer.type !== "offer") {
      throw new Error("expected session description to be an offer");
    }
    const send = (msg: PeerMessage) => sendPeerMessage(ws, msg, peerId);
    const storeTrack = (track: MediaStreamTrack) => storeRemoteTrack(peerId, track);
    const setStatus = (status: ConnectionStatus) => setPeerConnectionStatus(get, peerId, status);
    const peerConnectionPromise = createPeerConnection(
      send,
      storeTrack,
      sendStream.stream,
      mediaStream,
      twilioToken,
      setStatus,
      checkReadyToStream,
      offer
    );
    const readyNow = true;
    peerConnectionInfoPromise = createPeerConnectionInfoPromise(peerConnectionPromise, readyNow);
  }

  peerConnections.set(peerId, peerConnectionInfoPromise);
}

const handlePeerIceCandidate = async (get: StateGetter, peerId: UserId, candidate: RTCIceCandidate) => {
  const { peerConnections } = get().values;
  const peerConnectionInfoPromise = peerConnections.get(peerId);

  if (peerConnectionInfoPromise) {
    // wait for the connection to be created
    const peerConnectionInfo = await peerConnectionInfoPromise;
    const peerConnection = peerConnectionInfo.connection;
    // wait for the remote description to be set
    await peerConnectionInfo.isReadyForIceCandidates;
    // ok, now add the candidate
    await peerConnection.addIceCandidate(candidate);
  } else {
    console.error('got ICE candidate before creating PeerConnection for peer', peerId);
  }
}

const handlePeerMessage = async (set: SetFunc, get: StateGetter, peerId: UserId, msg: PeerMessage) => {
  if (msg.kind === "Hello") {
    handlePeerHello(set, peerId);
  }
  else if (msg.kind === "Offer") {
    const description: RTCSessionDescription = JSON.parse(msg.description);
    await handlePeerOffer(get, peerId, description);
  }
  else if (msg.kind === "IceCandidate") {
    const candidate: RTCIceCandidate = JSON.parse(msg.candidate)
    await handlePeerIceCandidate(get, peerId, candidate)
  }
  else {
    console.error('ignored other peer message:', msg);
  }
}

const storeRemoteTrack = (set: SetFunc, get: StateGetter, peerId: UserId, track: MediaStreamTrack) => {
  const { remoteMediaStreams, audio } = get().values;

  const remoteMediaStreamsCopy = new Map(remoteMediaStreams);

  if (!audio) {
    throw new Error("audio context not initialized");
  }

  let stream = remoteMediaStreamsCopy.get(peerId);

  if (stream) {
  } else {
    // Create a new stream for the peer
    stream = new MediaStream();
  }

  stream.addTrack(track)

  const notYetStored = !audio.peerInputs.get(peerId);
  const hasAudio = stream.getAudioTracks().length > 0;

  if (notYetStored && hasAudio) {
    // create a new node in the local audio graph for the peer
    const peerInput = audio.context.createMediaStreamSource(stream);
    // store the node
    audio.peerInputs.set(peerId, peerInput);
    // route their audio
    peerInput.connect(audio.hearAnalyser);
  }

  remoteMediaStreamsCopy.set(peerId, stream);
  set(() => ({ remoteMediaStreams: remoteMediaStreamsCopy }));
}

const createOnStartStreamingChange = (peerId: UserId): AudioPeerInstructions => ({
  targetsDiff: [{ kind: "AddTarget", peerId }],
  forwardDiff: null,
});

const createOnStopStreamingChange = (peerId: UserId): AudioPeerInstructions => ({
  targetsDiff: [{ kind: "RemoveTarget", peerId }],
  forwardDiff: null,
});

const createForwardingChange = (forwardInputs: boolean): AudioPeerInstructions => ({
  targetsDiff: [],
  forwardDiff: forwardInputs ? { kind: "StartForwarding" } : { kind: "StopForwarding" },
});


const sendAudioStateChange = (get: StateGetter, change: AudioPeerInstructions) => {
  console.log('send audio state change');
  const { ws } = get().values;
  const msg: ClientMessage = {
    kind: "AudioStateChange",
    ...change,
  }
  sendClientMessage(ws, msg);
}

const sendAudioGraphRequest = (get: StateGetter, graphType: AudioGraphType) => {
  const { ws } = get().values;
  const msg: ClientMessage = {
    kind: "AudioGraphRequest",
    ...graphType
  }
  sendClientMessage(ws, msg);
}

const sendReadyToStream = (get: StateGetter) => {
  const { ws } = get().values;
  const msg: ClientMessage = {
    kind: "ReadyToStream",
  };
  sendClientMessage(ws, msg);
}

const setReadyToStream = (set: SetFunc) => {
  set(() => ({ readyToStream: true }))
}

const checkReadyToStream = (get: StateGetter, set: SetFunc) => {
  const { peers, peerConnectionStatuses, readyToStream: wasReady } = get().values;

  const checkIsConnected = (peer: PeerDescription) => {
    const status = peerConnectionStatuses.get(peer.userId);
    return (status === ConnectionStatus.Connected);
  }

  // Ready when connected to all known peers
  const nowReady = peers.every(checkIsConnected);

  // Update local & remote state
  if (nowReady && !wasReady) {
    setReadyToStream(set);
    sendReadyToStream(get);
  }
}

const handleAudioGraph = (set: SetFunc, get: StateGetter, description: AudioGraphDescription) => {
  const { adjMat, peers } = description;
  set(() => ({ peers, audioGraphAdjMat: adjMat }));
  removeOldPeerConnections(set, get);
  console.log('stored adj mat', adjMat);
}

const peerDescriptionsFromUserIds = (get: StateGetter, userIds: UserId[]): PeerDescription[] => {
  const { peers } = get().values;
  return userIds.map(
    // TODO: this is not efficient
    userId => peers.find(
      peer => peer.userId === userId
    )
  ).filter(peer => peer !== undefined) as PeerDescription[];
}

const handleStageUpdate = (set: SetFunc, get: StateGetter, stage: LinearStage) => {
  const stageDescription = peerDescriptionsFromUserIds(get, stage.userIds);

  set(() => ({
    stage: stageDescription,
    stageEnabled: stage.enabled
  }));

  console.log('stored stage', stage);
}


const startStreaming = (set: SetFunc, get: StateGetter, peerId: UserId) => {
  const { audio } = get().values;
  const { sendAudioStateChange } = get().actions;

  if (!audio) {
    throw new Error("audio context must be initialized");
  }

  const sendStream = audio.sendStreams.get(peerId);
  if (!sendStream) {
    throw new Error("Peer has no node in audio graph");
  }

  // unmute audio sent to this peer
  const sendTracks = sendStream.stream.getAudioTracks();
  for (const track of Array.from(sendTracks)) {
    track.enabled = true;
  }

  // now streaming to this peer
  const audioCopy = { ...audio };
  audioCopy.sendEnabled.set(peerId, true);
  set(() => ({ audio: audioCopy }));

  const change = createOnStartStreamingChange(peerId);
  sendAudioStateChange(change);
  console.log('started streaming to', peerId);
};

const stopStreaming = (set: SetFunc, get: StateGetter, peerId: UserId) => {
  const { audio } = get().values;
  const { sendAudioStateChange } = get().actions;

  if (!audio) {
    throw new Error("audio context must be initialized");
  }

  const sendStream = audio.sendStreams.get(peerId);
  if (!sendStream) {
    throw new Error("Peer has no node in audio graph");
  }

  // mute audio sent to this peer
  const sendTracks = sendStream.stream.getAudioTracks();
  for (const track of Array.from(sendTracks)) {
    track.enabled = false;
  }

  // no longer streaming to this peer
  const audioCopy = { ...audio };
  audioCopy.sendEnabled.set(peerId, false);
  set(() => ({ audio: audioCopy }));

  const change = createOnStopStreamingChange(peerId);
  sendAudioStateChange(change);
  console.log('stopped streaming to', peerId);
};

const startForwarding = (get: StateGetter) => {
  const { audio } = get().values;
  const { sendAudioStateChange } = get().actions;

  if (!audio) {
    throw new Error("audio context not initialized");
  }

  // Connect inputs to output
  // (after delay to match my input latency)
  audio.peerDelay.connect(audio.sendAnalyser);

  const change = createForwardingChange(true);
  sendAudioStateChange(change);
  console.log('enabled forwarding');
}

const stopForwarding = (get: StateGetter) => {
  const { audio } = get().values;
  const { sendAudioStateChange } = get().actions;

  if (!audio) {
    throw new Error("audio context not initialized");
  }

  // Disconnect peer inputs from output
  audio.peerDelay.disconnect(audio.sendAnalyser)

  const change = createForwardingChange(false);
  sendAudioStateChange(change);
  console.log('enabled forwarding');
}

const handleAudioStateCommand = (get: StateGetter, instructions: AudioPeerInstructions) => {
  const {
    startForwarding,
    stopForwarding,
    startStreaming,
    stopStreaming
  } = get().actions;

  // update forwarding
  if (instructions.forwardDiff !== null) {
    const { kind } = instructions.forwardDiff;
    if (kind === "StartForwarding") {
      startForwarding();
    } else if (kind === "StopForwarding") {
      stopForwarding();
    }
  }

  // update targets
  for (const delta of instructions.targetsDiff) {
    if (delta.kind === "AddTarget") {
      startStreaming(delta.peerId);
    } else if (delta.kind === "RemoveTarget") {
      stopStreaming(delta.peerId);
    }
    else {
      throw new Error('unknown audio instruction', delta);
    }
  }
}

const handleWebSocketMessage = (set: SetFunc, get: StateGetter) => (ev: MessageEvent) => {
  // console.log('ws event: ', ev);
  const msg: MessageToUser = JSON.parse(ev.data);
  if (msg.from === "Server") {
    if (msg.kind === "RegisterSuccess") {
      handleRegisterSuccess(set, get, msg.userId, msg.twilioToken);
    }
    else if (msg.kind === "NewPeer") {
      handleNewPeer(get, msg.peer);
    }
    else if (msg.kind === "AudioGraph") {
      handleAudioGraph(set, get, msg.graph);
    }
    else if (msg.kind === "AudioStateCommand") {
      handleAudioStateCommand(get, msg.instructions);
    }
    else if (msg.kind === "StageUpdate") {
      handleStageUpdate(set, get, msg.stage);
    }
  } else if (msg.from === "Peer") {
    const { peerId } = msg;
    handlePeerMessage(set, get, peerId, msg.msg).catch(console.error);
  }
  else {
    console.error('unhandled ws message:', msg)
  }
}

const getWsUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return "wss://api.webofsong.com/ws";
  } else {
    // return `ws://${window.location.hostname}:8080/ws`;
    return "wss://api.webofsong.com/ws";
  }
}

const setServerConnectionStatus = (set: SetFunc, status: ConnectionStatus) => {
  set(() => ({ webSocketStatus: status }));
}

const initErrorHandling = (get: StateGetter) => {
  window.onerror = (err) => {
    const { ws } = get().values
    sendClientMessage(ws, {
      kind: "ClientError",
      err: JSON.stringify(err)
    });

    // Don't disable default error handler
    return false;
  };
}

const initReconnector = (set: SetFunc, get: StateGetter) => {
  // Try to reconnect when websocket disconnects
  const retryEvery = 1000 // ms
  const reconnectTimer = setInterval(() => {
    const { webSocketStatus } = get().values;
    if (webSocketStatus === ConnectionStatus.Disconnected) {
      console.log('attempting to reconnect to server');
      initWebSocket(set, get);
    }
  }, retryEvery);
  set(() => ({ reconnectTimer }));
}

const cleanup = (get: StateGetter) => {
  const { ws, reconnectTimer } = get().values;

  if (ws) {
    ws.close();
  }

  if (reconnectTimer) {
    clearInterval(reconnectTimer);
  }
}

const resetPeerConnections = (set: SetFunc, get: StateGetter) => {
  const { peerConnections } = get().values;

  // Close peer connections
  peerConnections.forEach((connectionPromise) => {
    connectionPromise.then(connectionInfo => {
      const conn = connectionInfo.connection;
      conn.close();
    }).catch(console.error);
  });

  // Delete references to them
  set(() => ({ peerConnections: new Map() }));
}

const disconnect = (set: SetFunc, get: StateGetter) => {
  const { ws } = get().values;
  const normalCloseStatus = 1000;
  ws?.close(normalCloseStatus, "manually closed");
  // Can't user old peer connections anymore
  // because we'll have a new userId when we reconnect,
  // so peers will be confused.
  resetPeerConnections(set, get);
}

const panic = (get: StateGetter) => {
  const { ws } = get().values;
  const msg: ClientMessage = {
    kind: "Panic"
  };
  sendClientMessage(ws, msg)
}

const sendClientError = (get: StateGetter, err: string) => {
  const { ws } = get().values;
  const msg: ClientMessage = {
    kind: "ClientError",
    err,
  }
  sendClientMessage(ws, msg);
}

const initWebSocket = (set: SetFunc, get: StateGetter) => {
  const url = getWsUrl();
  const ws = new WebSocket(url);

  ws.onopen = () => {
    const { register } = get().actions;

    setServerConnectionStatus(set, ConnectionStatus.Connected);

    // Register with the server
    register();
  }

  ws.onclose = () => {
    setServerConnectionStatus(set, ConnectionStatus.Disconnected);

    // Can't user old peer connections anymore
    // because we'll have a new userId when we reconnect,
    // so peers will be confused.
    resetPeerConnections(set, get);
  }

  ws.onmessage = handleWebSocketMessage(set, get);

  set(() => ({ ws, webSocketStatus: ConnectionStatus.Connecting }));
}

const initAudioContext = (set: SetFunc, get: StateGetter) => {
  const context = new AudioContext();

  // recorder for user recordings
  const recordStream = context.createMediaStreamDestination();
  const userRecorder = new MediaRecorder(recordStream.stream);

  // recorder for latency test
  const latencyStream = context.createMediaStreamDestination();
  // the actual recorder
  const latencyRecorder = new MediaRecorder(recordStream.stream);

  // delay peer audio to match my input
  const maxDelaySecs = 1.0;
  const peerDelay = context.createDelay(maxDelaySecs);

  // save recordings appropriately
  userRecorder.ondataavailable = (ev: BlobEvent) => {
    const blob = ev.data;
    const now = new Date();
    const fileName = `${now.toISOString()}.webm`
    const file = new File([blob], fileName, { type: blob.type });
    // append this one to list of recordings
    set(({ recordings }) => ({ recordings: recordings.concat([file]) }));
  }

  // Calculate latency when test is finished
  latencyRecorder.ondataavailable = async (ev: BlobEvent) => {
    console.log('latency dataavailable');

    const blob = ev.data;

    // Get the microphone recording
    const recordedBuffer = await blob.arrayBuffer();
    const recorded = await context.decodeAudioData(recordedBuffer);

    // Store the recording
    set(() => ({ latencyRecording: recorded }));

    // We may encounter a server error
    try {
      const latency = await fetchLatency(set, recorded);
      handleLatencyResult(set, get, latency);
    } catch (err) {
      console.error("Server error during latency calculation: ", err);
    }
  }

  // my input
  const meAnalyser = context.createAnalyser();
  // what I hear
  const hearAnalyser = context.createAnalyser();
  // to be sent
  const sendAnalyser = context.createAnalyser();

  // my audio should be sent to peers
  meAnalyser.connect(sendAnalyser);

  // what I hear should be delayed before
  // being recorded or forwarded
  hearAnalyser.connect(peerDelay);

  // what I hear + my input should be recorded
  peerDelay.connect(recordStream);
  meAnalyser.connect(recordStream);

  // latency test just needs my audio input
  meAnalyser.connect(latencyStream)

  // peer inputs should be played in my browser
  hearAnalyser.connect(context.destination);

  const audio: AudioValues = {
    context,
    peerInputs: new Map(),
    sendStreams: new Map(),
    recordStream, // TODO: don't need to save stream?
    userRecorder,
    latencyStream,
    latencyRecorder,
    meAnalyser,
    hearAnalyser,
    sendAnalyser,
    peerDelay,
    sendEnabled: new Map(),
  };

  set(() => ({ audio }));
}

// Initialize Web Worker to offload heavy computations
const initWebWorker = (set: SetFunc, get: StateGetter) => {
  const { audio } = get().values;

  if (!audio) {
    throw new Error("Cannot init latency worker before audio init");
  }

  if (window.Worker) {
    console.log('INIT WORKER');
    const worker = new Worker(new URL("./worker.ts", import.meta.url));

    worker.onmessage = (ev: MessageEvent<FromWorkerMessage>) => {
      const msg = ev.data;
      if (msg.kind === FromWorkerMessageKind.AudioSummaryResponse) {
        set(() => ({ latencyRecordingSummary: msg.summary }));
      } else {
        console.error('Unmatched message from worker:', msg);
      }
    }

    // Store worker in app state
    set(() => ({ worker: worker }));
  } else {
    console.error("Your browser doesn't support Web Workers!");
  }
}

const initMediaStream = async (set: SetFunc, get: StateGetter) => {
  const { muteMe, audio, mediaDevices } = get().values;
  const { audioInput, videoInput } = mediaDevices;

  console.log('getting media stream');
  const mediaStream = await getMediaStream(audioInput, videoInput);
  console.log('got media stream');

  // mute if requested
  applyMuteMe(mediaStream, muteMe);

  if (!audio) {
    throw new Error("audio context must be initialized before media stream");
  }

  // Disconnect old audio input if present
  if (audio.myInput) {
    audio.myInput.disconnect(audio.meAnalyser);
  }

  // connect my audio to AudioContext
  const myInput = audio.context.createMediaStreamSource(mediaStream);
  myInput.connect(audio.meAnalyser);

  set(() => ({ mediaStream, audio: { ...audio, myInput } }))
}

const applyAudioStreamConstraints = async (get: StateGetter, constraints: MediaTrackConstraints) => {
  const { mediaStream } = get().values;

  if (!mediaStream) {
    throw new Error("Cannot apply constraints before media stream init");
  }

  const applyPromises = mediaStream
    .getAudioTracks()
    .map(track => track.applyConstraints(constraints));

  await Promise.all(applyPromises);
  console.log('audio stream constraints applied')
}

const resumeAudioContext = async (get: StateGetter) => {
  const { audio } = get().values;

  if (!audio) {
    throw new Error("Cannot resume audio context before init");
  }

  await audio.context.resume();
  console.log('audio context resumed');
}

const sendStageUpdate = (get: StateGetter) => {
  const { ws, stage, stageEnabled, muteCrowd } = get().values;

  const userIds = stage.map(peer => peer.userId);

  const msg: ClientMessage = {
    kind: "StageUpdate",
    enabled: stageEnabled,
    muteCrowd: muteCrowd,
    userIds
  }
  sendClientMessage(ws, msg);
}

const setStageEnabled = (set: SetFunc, get: StateGetter, enabled: boolean) => {
  set(() => ({ stageEnabled: enabled }));
  sendStageUpdate(get);
}

const setMuteCrowd = (set: SetFunc, get: StateGetter, muteCrowd: boolean) => {
  set(() => ({ muteCrowd }));
  sendStageUpdate(get);
}

const applyMuteMe = (mediaStream: MediaStream, muteMe: boolean) => {
  const audioTracks = mediaStream?.getAudioTracks()
  for (const track of audioTracks) {
    track.enabled = !muteMe;
  }
}

const setMuteMe = (set: SetFunc, get: StateGetter, muteMe: boolean) => {
  const { mediaStream } = get().values;

  if (mediaStream) {
    applyMuteMe(mediaStream, muteMe);
  }

  set(() => ({ muteMe }));
}

const updateStage = (set: SetFunc, get: StateGetter, stage: PeerDescription[]) => {
  console.log('update stage', stage);
  set(() => ({ stage }))
  sendStageUpdate(get);
}

const addToStage = (set: SetFunc, get: StateGetter, userId: UserId) => {
  const { stage, peers } = get().values;
  console.log('Add user', userId, 'to stage');

  const peer = peers.find(peer => peer.userId === userId);

  console.log('aus inner:', peer);

  console.log('aus old stage:', stage);

  let newStage = stage.slice();

  if (peer) {
    newStage.push(peer)
  }

  console.log('aus new stage:', stage);

  updateStage(set, get, newStage);
}

const removeFromStage = (set: SetFunc, get: StateGetter, userId: UserId) => {
  const { stage } = get().values;

  console.log('Remove user', userId, 'from stage');
  console.log('REMOVE STAGE INNER')
  console.log('old stage:', stage);

  const newStage = stage.slice();
  const index = stage.findIndex(peer => peer.userId === userId);
  newStage.splice(index, 1);

  console.log('new stage:', newStage);
  updateStage(set, get, newStage);
}

const startUserRecording = (set: SetFunc, get: StateGetter) => {
  const { audio } = get().values;

  if (!audio) {
    throw new Error("cannot start recording before initializing audio context");
  }

  console.log('start recording');
  audio.userRecorder.start();
  set(() => ({ nowRecording: true }));
}

const stopUserRecording = (set: SetFunc, get: StateGetter) => {
  const { audio } = get().values;

  if (!audio) {
    throw new Error("cannot stop recording before initializing audio context");
  }

  console.log('stop recording');
  audio.userRecorder.stop();
  set(() => ({ nowRecording: false }));
}

const discardRecording = (set: SetFunc, i: number) => {
  console.log('discard recording', i);
  set(({ recordings }) => {
    // copy the list
    const newList = recordings.slice();
    // remove the selected element
    newList.splice(i, 1);
    return { recordings: newList };
  });
}

const startLatencyRecording = (set: SetFunc, get: StateGetter) => {
  const { audio } = get().values;

  if (!audio) {
    throw new Error("cannot start latency recording before initializing audio context");
  }

  console.log('start latency recording');
  audio.latencyRecorder.start();

  set(() => ({
    latency: { status: LatencyStatus.Measuring },
    latencyRecording: null,
    latencyRecordingSummary: null,
  }));
}

const stopLatencyRecording = (set: SetFunc, get: StateGetter) => {
  const { audio } = get().values;

  if (!audio) {
    throw new Error("cannot stop recording before initializing audio context");
  }

  console.log('stop latency recording');
  audio.latencyRecorder.stop();
  console.log('stopped latency recording');

  set(() => ({ latency: { status: LatencyStatus.Calculating } }));
}

const setAudioInput = (set: SetFunc, deviceId: string) => {
  console.log('set audio input:', deviceId);
  set(({ mediaDevices }) => ({
    mediaDevices: {
      ...mediaDevices,
      audioInput: deviceId,
    }
  }))
}

const setAudioOutput = (set: SetFunc, deviceId: string) => {
  console.log('set audio output:', deviceId);
  set(({ mediaDevices }) => ({
    mediaDevices: {
      ...mediaDevices,
      audioOutput: deviceId,
    }
  }))
}

const setVideoInput = (set: SetFunc, deviceId: string) => {
  console.log('set video input:', deviceId);
  set(({ mediaDevices }) => ({
    mediaDevices: {
      ...mediaDevices,
      videoInput: deviceId,
    }
  }))
}

const requestAudioSummary = (get: StateGetter, audio: AudioBuffer, width: number) => {
  const { worker } = get().values;
  const { data } = extractAudioData(audio);
  sendWorkerMsg(worker, {
    kind: ToWorkerMessageKind.AudioSummaryRequest,
    audio: data,
    width
  });
}

interface Actions {
  setUsername: (name: string) => void;
  setLatency: (latency: LatencyState) => void;
  fakeValidLatency: (latency: LatencyState) => void;
  register: () => void;
  initErrorHandling: () => void;
  initWebSocket: () => void;
  initReconnector: () => void;
  initAudioContext: () => void;
  initLatencyWorker: () => void;
  initMediaStream: () => Promise<void>;
  applyAudioStreamConstraints: (constraints: MediaTrackConstraints) => Promise<void>,
  resumeAudioContext: () => Promise<void>;
  storeRemoteTrack: (peerId: UserId, track: MediaStreamTrack) => void;
  sendAudioStateChange: (change: AudioPeerInstructions) => void;
  sendAudioGraphRequest: (typ: AudioGraphType) => void;
  checkReadyToStream: () => void;
  startStreaming: (peerId: UserId) => void;
  stopStreaming: (peerId: UserId) => void;
  startForwarding: () => void;
  stopForwarding: () => void;
  setStageEnabled: (enabled: boolean) => void;
  setMuteCrowd: (muteCrowd: boolean) => void;
  setMuteMe: (muteMe: boolean) => void;
  updateStage: (items: any[]) => void;
  addToStage: (userId: UserId) => void;
  removeFromStage: (userId: UserId) => void;
  sendStageUpdate: () => void;
  sendClientError: (err: string) => void;
  cleanup: () => void;
  disconnect: () => void;
  panic: () => void;
  startUserRecording: () => void;
  stopUserRecording: () => void;
  startLatencyRecording: () => void;
  stopLatencyRecording: () => void;
  discardRecording: (i: number) => void;
  setAudioInput: (deviceId: string) => void;
  setAudioOutput: (deviceId: string) => void;
  setVideoInput: (deviceId: string) => void;
  requestAudioSummary: (audio: AudioBuffer, width: number) => void;
}

// State definition
export const useAppState = create<State>((setState, get) => {
  const set = (x: ValueSetter) => setState(action(x));
  const state: State = {
    values: initialValues,
    actions: {
      setUsername: (name) => setUsername(set, name),
      setLatency: (latency) => setLatency(get, set, latency),
      fakeValidLatency: () => setLatency(get, set, {
        status: LatencyStatus.Valid,
        result: {
          millis: 250,
          maxCorr: 1,
        },
      }),
      register: () => register(get),
      initErrorHandling: () => initErrorHandling(get),
      initWebSocket: () => initWebSocket(set, get),
      initReconnector: () => initReconnector(set, get),
      initAudioContext: () => initAudioContext(set, get),
      initLatencyWorker: () => initWebWorker(set, get),
      initMediaStream: () => initMediaStream(set, get),
      applyAudioStreamConstraints: (constraints: MediaTrackConstraints) => applyAudioStreamConstraints(get, constraints),
      resumeAudioContext: () => resumeAudioContext(get),
      storeRemoteTrack: (peerId, track) => storeRemoteTrack(set, get, peerId, track),
      sendAudioStateChange: (change) => sendAudioStateChange(get, change),
      sendAudioGraphRequest: (typ) => sendAudioGraphRequest(get, typ),
      checkReadyToStream: () => checkReadyToStream(get, set),
      startStreaming: (peerId: UserId) => startStreaming(set, get, peerId),
      stopStreaming: (peerId: UserId) => stopStreaming(set, get, peerId),
      startForwarding: () => startForwarding(get),
      stopForwarding: () => stopForwarding(get),
      setStageEnabled: (enabled: boolean) => setStageEnabled(set, get, enabled),
      setMuteCrowd: (muteCrowd: boolean) => setMuteCrowd(set, get, muteCrowd),
      setMuteMe: (muteMe: boolean) => setMuteMe(set, get, muteMe),
      updateStage: (items: any[]) => updateStage(set, get, items),
      addToStage: (userId: UserId) => addToStage(set, get, userId),
      removeFromStage: (userId: UserId) => removeFromStage(set, get, userId),
      sendStageUpdate: () => sendStageUpdate(get),
      sendClientError: (err: string) => sendClientError(get, err),
      cleanup: () => cleanup(get),
      disconnect: () => disconnect(set, get),
      panic: () => panic(get),
      startUserRecording: () => startUserRecording(set, get),
      stopUserRecording: () => stopUserRecording(set, get),
      startLatencyRecording: () => startLatencyRecording(set, get),
      stopLatencyRecording: () => stopLatencyRecording(set, get),
      discardRecording: (i: number) => discardRecording(set, i),
      setAudioInput: (deviceId: string) => setAudioInput(set, deviceId),
      setAudioOutput: (deviceId: string) => setAudioOutput(set, deviceId),
      setVideoInput: (deviceId: string) => setVideoInput(set, deviceId),
      requestAudioSummary: (audio: AudioBuffer, width: number) => requestAudioSummary(get, audio, width),
    },
  };

  // Expose initial state to browser console
  (window as any).state = state;

  return state;
});