import ndarray, { NdArray } from "ndarray";
//@ts-ignore
import pool from 'ndarray-scratch';
import ops from 'ndarray-ops';

export const extractAudioData = (buf: AudioBuffer): NdArray<Float32Array> => {
    // Take average of all channels
    const numChannels = buf.numberOfChannels;
    const audioArray: NdArray<Float32Array> = pool.zeros([buf.length], 'float');
    for (let i = 0; i < numChannels; i++) {
        const channelData = buf.getChannelData(i);
        ops.addeq(audioArray, ndarray(channelData));
    }
    ops.divseq(audioArray, numChannels);

    return audioArray;
}