import { UserId } from "wos-types/UserId";
import { useAppState } from "./state";

interface Props {
    userId: UserId;
}

function UserStageCheckbox(props: Props) {
    const { addToStage, removeFromStage } = useAppState(state => state.actions);
    const stage = useAppState(state => state.values.stage);

    console.log('stage:', stage);

    const onStage = stage.find(peer => peer.userId === props.userId) !== undefined;
    console.log('user', props.userId, 'onstage =', onStage);

    const onChange = (ev: React.FormEvent<HTMLInputElement>) => {
        const { checked } = ev.currentTarget;
        if (onStage && !checked) {
            removeFromStage(props.userId);
        }
        else if (!onStage && checked) {
            addToStage(props.userId);
        }

    };

    return (
        <input
            type="checkbox"
            onChange={onChange}
            checked={onStage}
            style={{
                width: "30px",
                height: "30px",
            }}
        />
    )
}

export default UserStageCheckbox;