import MediaDeviceSelector from './MediaDeviceSelector';
import { useAppState } from './state';

function MediaDeviceSelectors() {
    const setAudioInput = useAppState(state => state.actions.setAudioInput);
    const setAudioOutput = useAppState(state => state.actions.setAudioOutput);
    const setVideoInput = useAppState(state => state.actions.setVideoInput);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <MediaDeviceSelector kind="audioinput" label="Audio Input" onChange={setAudioInput} />
            <MediaDeviceSelector kind="audiooutput" label="Audio Output" onChange={setAudioOutput} />
            <MediaDeviceSelector kind="videoinput" label="Video Input" onChange={setVideoInput} />
        </div>
    );
}

export default MediaDeviceSelectors;