interface Props {
    stream?: MediaStream;
}

function UserVideo(props: Props) {
    const { stream } = props;

    return <video
        className="video"
        autoPlay
        playsInline
        // audio is routed through AudioContext
        muted={true}
        ref={(videoNode) => {
            if (videoNode && stream) {
                videoNode.srcObject = stream;
            }
        }} />;
}

export default UserVideo;