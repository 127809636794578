import { useAppState } from './state';
import { LatencyStatus } from './types';

interface FormElements extends HTMLFormControlsCollection {
  nameInput: HTMLInputElement;
}

interface LoginFormElements extends HTMLFormElement {
  readonly elements: FormElements
}

function LoginForm() {
  const setUsername = useAppState(state => state.actions.setUsername);
  const latency = useAppState(state => state.values.latency);

  const latencyIsValid = latency.status === LatencyStatus.Valid;

  const handleSubmit = async (e: React.FormEvent<LoginFormElements>) => {
    console.log('submit');

    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const nameInput = e.currentTarget.elements.nameInput;
    const username = nameInput.value;

    if (username && latencyIsValid) {
      setUsername(username);
      console.log('username set', username);
      console.log('latency is', latency.result.millis, 'ms');
    } else {
      alert("username is required.")
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ margin: "5px" }}>
      <div style={{ margin: "5px" }}>
        <label>Your name: </label>
        <input id="nameInput" type="text" />
      </div>
      <input
        id="loginSubmit"
        type="submit"
        value="Join"
        disabled={!latencyIsValid}
        style={{ margin: "3px" }}
      />
    </form>
  );
}

export default LoginForm;
