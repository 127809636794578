
import { useAppState } from "./state";

function StageCheckbox() {
    const { setStageEnabled } = useAppState(state => state.actions);
    const { stageEnabled } = useAppState(state => state.values);

    const onChange = (ev: React.FormEvent<HTMLInputElement>) => {
        const { checked } = ev.currentTarget;
        setStageEnabled(checked);
    };

    return (
        <input type="checkbox" onChange={onChange} checked={stageEnabled} />
    )
}

export default StageCheckbox;