import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useAppState } from "./state";
import LinearStageListItem from "./LinearStageListItem";
import { PeerDescription } from "wos-types/PeerDescription";
import LiveIndicator from "./LiveIndicator";

// a little function to help us with reordering the result
const reorder = (list: PeerDescription[], startIndex: number, endIndex: number): PeerDescription[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    margin: "5px",
    padding: grid,
    width: 250,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    // alignContent: "center",
    alignItems: "center",
    position: "relative",
});

function LinearStageList() {
    const stage = useAppState(state => state.values.stage);
    const updateStage = useAppState(state => state.actions.updateStage);
    const stageEnabled = useAppState(state => state.values.stageEnabled);

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newStage = reorder(
            stage,
            result.source.index,
            result.destination.index
        );

        updateStage(newStage);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        <div style={{
                            position: "absolute",
                            top: "10px",
                            left: "10%",
                        }}>
                            { stageEnabled && <LiveIndicator /> }
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <h3 style={{ marginBottom: "0.5em", marginTop: "0.5em" }}>Stage</h3>
                        </div>
                        {stage.map((peer, index) => <LinearStageListItem peer={peer} index={index} key={index} />)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default LinearStageList;


