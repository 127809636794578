interface Props {
    name: string;
}

function NameTag(props: Props) {
    return (
        <div style={{
            height: "1.5em",
            position: "absolute",
            bottom: "5px",
            right: "5px",
            backgroundColor: "black",
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "5px",
            paddingBottom: "0px",
        }}>
            <p style={{ color: "white", margin: "0px" }}>
                {props.name}
            </p>
        </div>
    )
}

export default NameTag;