import { RefCallback, useRef } from "react";
import { useAppState } from "./state";
import { LatencyErrorKind, LatencyStatus } from "./types";
import { LATENCY_CORR_THRESHOLD } from "./latency";
import AudioLevelMeter from "./AudioLevelMeter";
import WaveformViewer from "./WaveformViewer";

export default function LatencyTestWidget() {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const startLatencyRecording = useAppState(state => state.actions.startLatencyRecording);
    const stopLatencyRecording = useAppState(state => state.actions.stopLatencyRecording);
    const resumeAudioContext = useAppState(state => state.actions.resumeAudioContext);
    const latency = useAppState(state => state.values.latency);
    const latencyRecording = useAppState(state => state.values.latencyRecording);
    const audio = useAppState(state => state.values.audio);

    const inProgress = (
        latency.status === LatencyStatus.Calculating
        || latency.status === LatencyStatus.Measuring
    );

    const initAudioPlayback: RefCallback<HTMLAudioElement> = (ref) => {
        // Save ref
        audioRef.current = ref;

        // Once the audio clip ends, the test is over
        if (ref) {
            ref.onended = () => {
                // Stop recording
                stopLatencyRecording();
            };
        }
    };

    if (!audio) {
        return <p>Initializing audio...</p>
    }

    const startTest = async () => {
        // Make sure audio context is running
        await resumeAudioContext();

        // Start recording
        startLatencyRecording();

        // Start playing the audio
        audioRef.current?.play();
    }

    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
        <div style={{ border: "1px solid black", padding: "10px" }}>
            <h3>~ Latency test ~</h3>
            <audio ref={initAudioPlayback}>
                <source src="/latency-test-jingle.mp3" type="audio/mp3" />
                Your browser does not support the audio element.
            </audio>
            {latency.status === LatencyStatus.Measuring && <div>
                <p>Measuring latency...</p>
                <div style={{ display: "flex", flexDirection: "column", margin: "20px", alignItems: "center" }}>
                    <AudioLevelMeter label="me" analyser={audio.meAnalyser} />
                </div>
            </div>}
            {latency.status === LatencyStatus.Calculating && <div>
                <p>Analyzing results...</p>
            </div>}
            {latency.status === LatencyStatus.Valid && <div>
                <p>Your latency: {latency.result.millis} ms</p>
                <p>{Math.round(latency.result.maxCorr * 100)}% match</p>
            </div>}
            {latency.status === LatencyStatus.Invalid && <div>
                <p><b>ERROR! The latency test failed. Please try again.</b></p>
                {latency.error.kind === LatencyErrorKind.LowCorrelation && <>
                    <p>The microphone couldn't pick up the audio playback clearly enough.</p>
                    <p>Please unplug headphones, increase speaker volume, and minimize background noise.
                    </p>
                    {latency.error}
                    <p>Your test accuracy: {Math.round(100 * (latency.result?.maxCorr || -Infinity))}%</p>
                    <p>Minimum required: {100 * LATENCY_CORR_THRESHOLD}%</p>
                </>}
                {latency.error.kind === LatencyErrorKind.LatencyBounds && <p>
                    Your latency should be between 0ms and 1000ms, but it seems to be {latency.result?.millis || '?'}ms.
                </p>}
                {latency.error.kind === LatencyErrorKind.ServerError && <p>
                    Server error: <pre>{latency.error.msg}</pre>
                </p>}
            </div>}
            {!inProgress && <button onClick={startTest} disabled={inProgress}>
                Start Test
            </button>}
            <div>
                {latencyRecording && <WaveformViewer
                    audio={latencyRecording}
                    style={{
                        width: "400px",
                        maxWidth: "100%",
                        height: "200px",
                    }}
                />}
            </div>
        </div>
    </div>
}