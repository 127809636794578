import { AudioGraphAdjacencyMatrix } from "wos-types/AudioGraphAdjacencyMatrix";
import { useAppState } from "./state";
import { AudioStreamState } from "wos-types/AudioStreamState";

const CELL_SIZE = "50px";
const CELL_STYLE = { width: CELL_SIZE, height: CELL_SIZE };

interface MatLabelProps {
    username: string;
    rotate?: boolean;
}

function MatLabel(props: MatLabelProps) {
    let style: React.CSSProperties = {};

    if (props.rotate) {
        style.transform = 'rotate(-90deg)';
    }

    return (
        <th style={CELL_STYLE}><p style={style}>{props.username}</p></th>
    );
}

interface MatCellProps {
    state?: AudioStreamState;
}

function MatCell(props: MatCellProps) {
    let backgroundColor: string | undefined = undefined;
    if (props.state === "Forwarding") {
        backgroundColor = "blue";
    } else if (props.state === "Streaming") {
        backgroundColor = "green";
    } else if (props.state === "Stopped") {
        backgroundColor = "gray";
    }

    return <td style={{ backgroundColor, ...CELL_STYLE }} />;
}

interface Props {
    matrix?: AudioGraphAdjacencyMatrix
}

function AudioGraphAdjMat(props: Props) {
    const peers = useAppState(state => state.values.peers);

    if (!peers || peers.length === 0 || !props.matrix) {
        return <p>No audio graph yet...</p>;
    }

    if (peers.length !== props.matrix?.length) {
        return <p>Audio graph mismatch!</p>;
    }

    const emptyCell = <MatCell key="e" />

    const peerHeaders = peers.map(({ username }, j) => <MatLabel username={username} rotate={true} key={j} />);
    const headerCells = [emptyCell].concat(peerHeaders);
    const headerRow = <tr key="h">{headerCells}</tr>;

    console.log("matrix:", props.matrix);

    const outputRows = props.matrix.map((row, i) => {
        const outputCells = row.map((streamState, j) =>
            <MatCell state={streamState} key={j} />
        );

        const { username } = peers[i];

        return <tr key={i} style={CELL_STYLE}><MatLabel username={username} />{outputCells}</tr>;
    });

    return (
        <table>
            <thead>
                {headerRow}
            </thead>
            <tbody>
                {outputRows}
            </tbody>
        </table>
    );
}

export default AudioGraphAdjMat;