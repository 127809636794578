import LinearStageList from "./LinearStageList";
import UserStageCheckbox from "./UserStageCheckbox";
import { useAppState } from "./state";

export default function UsersPanel() {
    const userId = useAppState(state => state.values.userId);

    if (userId === undefined) {
        return <p>Loading user id...</p>
    }

    return <div id="users-panel" style={{
        display: "grid",
        gridTemplateRows: "1fr 50px",
        height: "100%",
    }}>
        <div id="stage-container" style={{
            gridRow: 1,
            minHeight: "90%",
            marginBottom: "auto",
        }}
            className="row-wrap-container">
            <LinearStageList />
        </div>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <div style={{
                gridRow: 2,
                display: "flex",
                marginTop: "auto",
                marginBottom: "auto",
                flexDirection: "row",
                alignItems: "center",
            }}>
                <UserStageCheckbox userId={userId} />
                <p><b>Get on stage!</b></p>
            </div>
        </div>
    </div>
}