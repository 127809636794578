import { PeerMessage } from "wos-types/PeerMessage";
import { TwilioToken } from "wos-types/TwilioToken";
import { ConnectionStatus } from "./types";

export async function getMediaStream(audioInput?: string, videoInput?: string): Promise<MediaStream> {
    const constraints: MediaStreamConstraints = {
        audio: {
            deviceId: audioInput,
            echoCancellation: false
        },
        video: {
            deviceId: videoInput,
            width: 250,
            height: 175,
            frameRate: 15,
        }
    };
    const devices = await navigator.mediaDevices.enumerateDevices();
    console.log('devices:', devices);
    console.log('about to gum');
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    console.log('gum success');
    return stream;
}

export interface PeerConnectionInfo {
    connection: RTCPeerConnection,
    isReadyForIceCandidates: Promise<void>,
    setReadyForIceCandidates: (...args: any[]) => void,
}

export function createPeerConnectionInfo(connection: RTCPeerConnection): PeerConnectionInfo {
    const info: Partial<PeerConnectionInfo> = { connection };
    info.isReadyForIceCandidates = new Promise((resolve, reject) => {
        info.setReadyForIceCandidates = resolve;
    });
    return info as PeerConnectionInfo;
}

type PeerSender = (msg: PeerMessage) => void;
type TrackStorer = (track: MediaStreamTrack) => void;

export async function createPeerConnection(
    send: PeerSender,
    storeTrack: TrackStorer,
    audioStream: MediaStream,
    videoStream: MediaStream,
    twilioToken: TwilioToken,
    setStatus: (status: ConnectionStatus) => void,
    checkReadyToStream?: () => void,
    remoteDescription?: RTCSessionDescription
): Promise<RTCPeerConnection> {

    const iceServers = twilioToken.iceServers as RTCIceServer[];
    const config: RTCConfiguration = { iceServers }

    const peerConnection = new RTCPeerConnection(config);
    console.log('created PC:', peerConnection);

    peerConnection.onicecandidate = ({ candidate }) => {
        if (candidate) {
            const candidateJson = JSON.stringify(candidate.toJSON());
            const msg: PeerMessage = {
                kind: "IceCandidate",
                candidate: candidateJson,
            };
            send(msg)
        }
    };

    peerConnection.onconnectionstatechange = (ev) => {
        const state = peerConnection.connectionState;

        if (state === 'connecting') {
            setStatus(ConnectionStatus.Connecting);
        } else if (state === 'connected') {
            setStatus(ConnectionStatus.Connected);

            // Only relevant for connection offers
            // since initial connections are initiated by others
            // upon joining the server
            if (checkReadyToStream) {
                checkReadyToStream();
            }
        } else {
            setStatus(ConnectionStatus.Disconnected);
        }
    }

    peerConnection.onsignalingstatechange = (ev) => {
        console.log('CURRENT SIGNALING STATE:', peerConnection.signalingState);
    }

    peerConnection.onicecandidateerror = (ev) => {
        console.debug('ICE candidate error:', ev);
    }

    peerConnection.onicegatheringstatechange = (ev) => {
        console.log('CURRENT ICE STATE:', peerConnection.iceGatheringState);
    }

    peerConnection.ontrack = (ev) => {
        console.log("REMOTE TRACK:", ev);
        storeTrack(ev.track);
        console.log('stored track', ev.track);
    }

    // Add media tracks
    audioStream.getAudioTracks()?.forEach((track) => {
        console.log('ADD AUDIO TRACK:', track);
        peerConnection.addTrack(track)
    });

    videoStream.getVideoTracks()?.forEach((track) => {
        console.log('ADD VIDEO TRACK:', track);
        peerConnection.addTrack(track)
    });

    if (remoteDescription) {
        // we're creating an answer
        console.log('A/sig-state:', peerConnection.signalingState);
        await peerConnection.setRemoteDescription(remoteDescription);
        console.log('B/sig-state:', peerConnection.signalingState);
        const answer = await peerConnection.createAnswer();
        console.log('C/sig-state:', peerConnection.signalingState);
        await peerConnection.setLocalDescription(answer);
        console.log('D/sig-state:', peerConnection.signalingState);
    } else {
        // we're creating an offer
        console.log('E/sig-state:', peerConnection.signalingState);
        const offer = await peerConnection.createOffer();
        console.log('F/sig-state:', peerConnection.signalingState);
        await peerConnection.setLocalDescription(offer);
        console.log('G/sig-state:', peerConnection.signalingState);
    }

    // make sure the description has been set (should be)
    if (!peerConnection.localDescription) {
        throw new Error("peer connection local description unset");
    }

    // Send initial offer
    const description = JSON.stringify(peerConnection.localDescription)
    send({ kind: "Offer", description });

    return peerConnection;
}
