import { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import HubIcon from '@mui/icons-material/Hub';
import VoicemailIcon from '@mui/icons-material/Voicemail';
import SecurityIcon from '@mui/icons-material/Security';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import AdminPanel from './AdminPanel';
import { useAdmin } from './admin';
import UsersPanel from './UsersPanel';
import MediaPanel from './MediaPanel';
import RecordingPanel from './RecordingPanel';
import GraphPanel from './GraphPanel';
import DonatePanel from './DonatePanel';

export default function RightPane() {
    const [tab, setTab] = useState(0);
    const admin = useAdmin();

    return <div style={{
        gridColumn: 2,
        display: "grid",
        gridTemplateRows: "1fr 72px",
        maxHeight: "calc(100vh - 150px)",
        borderLeft: "1px solid black"
    }}>
        <div style={{
            gridRow: 1,
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <div style={{
                marginLeft: "auto",
                marginRight: "auto",
                height: "100%",
            }}>
                {tab === 0 && <UsersPanel />}
                {tab === 1 && <MediaPanel />}
                {tab === 2 && <RecordingPanel />}
                {tab === 3 && <DonatePanel />}
                {admin && tab === 4 && <GraphPanel />}
                {admin && tab === 5 && <AdminPanel />}
            </div>
        </div>
        <div style={{
            gridRow: 2,
            marginLeft: "auto",
            marginRight: "auto",
            overflowX: "auto",
            width: "100%",
        }}>
            <Tabs
                variant="scrollable"
                value={tab}
                onChange={(_, val) => {
                    setTab(val);
                }}>
                <Tab icon={<PodcastsIcon />} label="Stage" />
                <Tab icon={<VolumeUpIcon />} label="Media" />
                <Tab icon={<VoicemailIcon />} label="Record" />
                <Tab icon={<VolunteerActivismIcon />} label="Donate" />
                {admin && <Tab icon={<HubIcon />} label="Graph" />}
                {admin && <Tab icon={<SecurityIcon />} label="Admin" />}
            </Tabs>
        </div>
    </div>
}