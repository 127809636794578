import NameTag from "./NameTag";
import UserVideo from "./UserVideo";
import { useAppState } from "./state";

interface Props {
    stream?: MediaStream;
}

function MyVideo(props: Props) {
    const username = useAppState(state => state.values.username);
    const label = username || "Me";
    const nametag = <NameTag name={label} />;

    return (
        <div className="video-container my-video">
            <UserVideo stream={props.stream} />
            {nametag}
        </div>
    );
}

export default MyVideo;