import { PeerDescription } from "wos-types/PeerDescription";
import { useAppState } from "./state";
import UserStageCheckbox from "./UserStageCheckbox";
import PeerConnectionStatus from "./PeerConnectionStatus";

interface UserItemProps {
  peer: PeerDescription
}

function UserItem(props: UserItemProps) {
  const peerId = props.peer.userId;
  const username = props.peer.username;
  const userId = useAppState(state => state.values.userId);
  const status = userId === peerId ? <p style={{color: "gray"}}>(me)</p> :
    <PeerConnectionStatus peerId={peerId} />;

  return (
    <div style={{ display: "flex" }}>
      {status}
      <p style={{marginLeft: "5px"}}>{username} (#{peerId.toString()})</p>
      <UserStageCheckbox userId={peerId} />
    </div>
  )
}

function UserList() {
  const users = useAppState(state => state.values.peers);

  const items = users.map((user) =>
    <UserItem peer={user} key={user.userId} />
  );

  return (
    <div className="user-list">
      <h2>Users</h2>
      <hr />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {items}
      </div>
    </div>
  );
}

export default UserList;
