import { Draggable, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import { PeerDescription } from "wos-types/PeerDescription";

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle): React.CSSProperties => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    width: "80%",

    // styles we need to apply on draggables
    ...draggableStyle
});


interface Props {
    peer: PeerDescription;
    index: number;
}

function LinearStageListItem(props: Props) {
    const { peer, index } = props;
    return (
        <Draggable key={peer.userId} draggableId={peer.userId.toString()} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                >
                    ⬇ {peer.username}
                </div>
            )}
        </Draggable>
    );
}

export default LinearStageListItem;


