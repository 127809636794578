import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { useAppState } from "./state";
import RightPane from "./RightPane";
import VideoGallery from "./VideoGallery";
import MobileView from "./MobileView";

function Main() {
    const initWebSocket = useAppState(state => state.actions.initWebSocket);
    const initReconnector = useAppState(state => state.actions.initReconnector);
    const applyAudioStreamConstraints = useAppState(state => state.actions.applyAudioStreamConstraints);
    const isMobile = useMediaQuery({ maxWidth: 1224 });

    useEffect(() => {
        initWebSocket();
        initReconnector();
    }, [
        applyAudioStreamConstraints,
        initWebSocket,
        initReconnector,
    ]);

    const baseStyle = {
        gridRow: 2,
    };

    const mobileStyle = {
        ...baseStyle
    };

    const desktopStyle = {
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: "1fr 500px",
        ...baseStyle,
    };

    if (isMobile) {
        return <MobileView />;
    } else {
        return <div style={isMobile ? mobileStyle : desktopStyle}>
            <VideoGallery />
            <RightPane />
        </div>
    }
}

export default Main;