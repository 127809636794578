import MuteMeCheckbox from "./MuteMeCheckbox";
import { useAppState } from "./state";

interface Props {
}

function AudioGraphController(props: Props) {
    const { sendAudioGraphRequest, disconnect, panic } = useAppState(state => state.actions);
    let randomOnClick = () => {
        sendAudioGraphRequest({ graphType: "Random" });
    };
    let muteAllOnClick = () => {
        sendAudioGraphRequest({ graphType: "MuteAll" });
    };
    let fullOnClick = () => {
        sendAudioGraphRequest({ graphType: "FullyConnected" });
    };
    let stageOnClick = () => {
        sendAudioGraphRequest({ graphType: "LinearStage" });
    };
    // let errorOnClick = () => {
    //     throw new Error("Why did you press the button???");
    // }
    return (
        <div className="audio-graph-controls" style={{ display: "flex", flexDirection: "column", alignItems: "center", border: "1px solid gray", padding: "5px"}}>
            <h2 style={{marginBottom: "5px", marginTop: "5px"}}>Controls</h2>
            <button style={{width: "70%", marginBottom: "5px"}} onClick={fullOnClick}>make fully connected</button>
            <button style={{width: "70%", marginBottom: "5px"}} onClick={muteAllOnClick}>mute all</button>
            <button style={{width: "70%", marginBottom: "5px"}} onClick={randomOnClick}>make random</button>
            <button style={{width: "70%", marginBottom: "5px"}} onClick={stageOnClick}>refresh stage</button>
            {/* <button style={{width: "70%", marginBottom: "5px"}} onClick={errorOnClick}>cause error</button> */}
            <button style={{width: "70%", marginBottom: "5px"}} onClick={disconnect}>reconnect</button>
            <button style={{width: "70%", height: "2em", marginBottom: "5px", backgroundColor: "red", color:"white"}} onClick={panic}>PANIC</button>
            <div style={{ display: "flex", alignItems: "center" }}>
                <MuteMeCheckbox />
                <p style={{ marginTop: "0px", marginBottom: "0px" }}>mute me</p>
            </div>
        </div>
    );
}

export default AudioGraphController;