import { useAppState } from "./state";

interface Props {
    i: number;
}

function DiscardRecording(props: Props) {
    const discard = useAppState(state => state.actions.discardRecording);

    return <button onClick={() => discard(props.i)}>✖</button>
}

export default DiscardRecording;