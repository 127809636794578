interface Props {
    closedUntil: string
}

export default function ComeBackLater(props: Props) {
    const textColor = "#2c2c2c";
    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{ height: "2em" }} />
        <div style={{ width: "400px", border: "2px solid #2c2c2c", backgroundColor: "#fbfbfb" }}>
            <h2 style={{color: textColor }}>Sorry, we're closed!</h2>
            <p style={{color: textColor }}>Our next scheduled event is: <br /><b>{props.closedUntil}</b></p>
            <p style={{color: textColor }}>Hope to see you then!</p>
        </div>
    </div>
}