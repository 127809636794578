import { ChangeEvent, useEffect, useState } from "react";
import { useAppState } from "./state";

async function listDevices(kind: MediaDeviceKind): Promise<MediaDeviceInfo[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(device => device.kind === kind);
}

interface Props {
    kind: MediaDeviceKind;
    label: string;
    onChange: (deviceId: string) => void;
}

function MediaDeviceSelector(props: Props) {
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const initMediaStream = useAppState(state => state.actions.initMediaStream);

    const onChangeWrapped = (ev: ChangeEvent<HTMLSelectElement>) => {
        const deviceId = ev.currentTarget.value;
        props.onChange(deviceId);
        initMediaStream();
    }

    const {kind, onChange} = props;

    useEffect(() => {
        // TODO: improve error handling
        listDevices(kind).then((devices) => {
            // Set dropdown options
            setDevices(devices);

            // Set default value in app state
            if (devices.length > 0) {
                const defaultDevice = devices[0];
                onChange(defaultDevice.deviceId);
            }
        }).catch(alert);
    }, [kind, onChange, setDevices]);

    const options = devices.map((device, index) =>
        <option value={device.deviceId} key={index}>{device.label}</option>
    );

    return <div style={{ margin: "5px" }}>
        <p>{props.label}</p>
        <select style={{ width: "175px" }} onChange={onChangeWrapped}>{options}</select>
    </div>;
}

export default MediaDeviceSelector;