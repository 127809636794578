import { UserId } from "wos-types/UserId";
import { useAppState } from "./state";
import { ConnectionStatus } from "./types";

interface Props {
    peerId: UserId
}

function PeerConnectionStatus(props: Props) {
    const status = useAppState(state => state.values.peerConnectionStatuses.get(props.peerId));

    if (status === ConnectionStatus.Connecting) {
        return <p style={{color: "orange"}}>?</p>;
    } else if (status === ConnectionStatus.Connected) {
        return <p style={{color: "green"}}>✓</p>;
    } else if (status === ConnectionStatus.Disconnected) {
        return <p style={{color: "red"}}>✘</p>;
    } else {
        return <></>;
    }

}

export default PeerConnectionStatus;
