import DiscardRecording from "./DiscardRecording";
import { useAppState } from "./state";

function RecordingList() {
    const recordings = useAppState(state => state.values.recordings);
    const playbacks = recordings.map((file, i) => {
        // TODO: release object URL during cleanup?
        // see https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL_static#memory_management
        const audioURL = URL.createObjectURL(file);
        return (
            <div key={i} style={{ display: "flex", gap: "10px", alignItems: "center", marginTop: "10px" }}>
                <DiscardRecording i={i} />
                <audio
                    controls
                    controlsList="nodownload"
                    src={audioURL}
                    style={{width: "300px"}}
                />
                <a href={audioURL} download={file.name}>Download</a>
            </div>
        );
    });

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {playbacks}
            </div>
        </div>
    );
}

export default RecordingList;