function Title() {
  const gitCommit = process.env.REACT_APP_GIT_COMMIT;
  const versionString = gitCommit ? `#${gitCommit}` : '???';
  return <div style={{
    gridRow: 1,
    gridColumn: "1/-1",
    borderBottom: "1px solid black"
  }}>
    <h1 style={{ marginBottom: "-0.25em" }}>🕸️ Web of Song 🎶</h1>
    <pre>Version {versionString}</pre>
  </div>;
}

export default Title;
