import { useSearchParams } from 'react-router-dom';

export const useAdmin = (): boolean => {
  let [searchParams] = useSearchParams();

  // Top-notch security
  let admin = false;
  if (searchParams.get("password") === "cucumber") {
    admin = true;
  }

  return admin;
}