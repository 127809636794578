import { useEffect } from 'react';

import { useAppState } from './state';

import './App.css';
import Main from './Main';
import Title from './Title';
import LoginScreen from './LoginScreen';
import ComeBackLater from './ComeBackLater';
import { useAdmin } from './admin';
import BottomBar from './BottomBar';

function App() {
  const username = useAppState(state => state.values.username);
  // const closedUntil = "Tuesday, 7/23 @ 10am PDT";
  const closedUntil = "not yet scheduled";
  // const closedUntil = null;
  const admin = useAdmin();
  const closed = closedUntil && !admin;
  const content = closed ? <ComeBackLater closedUntil={closedUntil} /> : username ? <Main /> : <LoginScreen />;

  const {
    initAudioContext,
    initLatencyWorker,
    initMediaStream,
    initErrorHandling,
    cleanup,
  } = useAppState(state => state.actions);

  useEffect(() => {
    initErrorHandling();
    initAudioContext();
    initLatencyWorker();
    initMediaStream().then(() => {
    }).catch((err) => {
      alert(`failed to access microphone & webcam: ${err}`)
    });
    return cleanup;
  }, [
    // deps
    initErrorHandling,
    initAudioContext,
    initLatencyWorker,
    initMediaStream,
    cleanup
  ]);

  return (
    <div className="App"
      style={{
        display: "grid",
        gridTemplateRows: "100px 1fr 50px",
        gridTemplateColumns: "1fr",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        margin: 0,
      }}
    >
      <Title />
      {content}
      <BottomBar />
    </div>
  );
}

export default App;
