import ndarray  from "ndarray";
import ops from 'ndarray-ops';

console.log('Initializing Web Worker');

export interface AudioSummary {
    mins: number[],
    maxs: number[],
}

export enum ToWorkerMessageKind {
    AudioSummaryRequest,
}
interface WrappedToWorkerMessage {
    worker: true,
    msg: ToWorkerMessage,
}
export type ToWorkerMessage = {
    kind: ToWorkerMessageKind.AudioSummaryRequest;
    audio: Float32Array;
    width: number;
}

export enum FromWorkerMessageKind {
    AudioSummaryResponse,
}
export enum LatencyErrorKind {
    NoJingle
}
export type FromWorkerMessage = {
    kind: FromWorkerMessageKind.AudioSummaryResponse;
    summary: AudioSummary
}

// interface State {}
// const workerState: State = {};

function summarizeAudio(audio: Float32Array, width: number): AudioSummary {
    console.log('start summarizing');
    const samplesPerPixel = Math.round(audio.length / width);

    const audioArray = ndarray(audio);

    const mins = new Array(width);
    const maxs = new Array(width);

    // Split samples into pixel bins
    for (let i = 0; i < width; i++) {
        const binStart = i * samplesPerPixel;
        const binEnd = (i + 1) * samplesPerPixel;
        const binSamples = audioArray.hi(binEnd).lo(binStart);

        // Get min & max for each bin
        mins[i] = ops.inf(binSamples)
        maxs[i] = ops.sup(binSamples)
    }

    console.log('finish summarizing');
    return { mins, maxs };
}

onmessage = function (ev: MessageEvent<WrappedToWorkerMessage>) {
    const postMessage = (msg: FromWorkerMessage) => this.postMessage(msg);
    const wrapper = ev.data;

    // Ignore irrelevant messages
    if (!wrapper.worker) {
        return;
    }
    const { msg } = wrapper;

    if (msg.kind === ToWorkerMessageKind.AudioSummaryRequest) {
        const summary = summarizeAudio(msg.audio, msg.width);
        postMessage({
            kind: FromWorkerMessageKind.AudioSummaryResponse,
            summary,
        });
    } else {
        console.error("Unmatched message to worker:", msg);
    }
}