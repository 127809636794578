import { RefCallback, useEffect, useLayoutEffect, useState } from "react"
import { AudioSummary } from "./worker";
import { useAppState } from "./state";

interface Props extends React.HTMLAttributes<HTMLCanvasElement> {
    audio: AudioBuffer,
}

function draw(ctx: CanvasRenderingContext2D, summary: AudioSummary, height: number) {
    const { mins, maxs } = summary;
    const width = mins.length;

    console.log('start drawing');

    // Convert from audio to canvas coords
    // NOTE: canvas origin is in top-left corner,
    // so y axis points downward.
    const getY = (val: number): number => (1 - val) * height / 2;

    // Abort if no data
    if (width === 0) {
        return;
    }

    // Draw first point
    ctx.moveTo(0, getY(maxs[0]));
    ctx.beginPath();

    // Draw maxs (forward)
    for (let i = 1; i < width; i++) {
        ctx.lineTo(i, getY(maxs[i]));
    }

    // Draw mins (reverse)
    for (let i = width - 1; i >= 0; i--) {
        ctx.lineTo(i, getY(mins[i]));
    }

    // Return to first point
    ctx.closePath();

    // Style the path
    ctx.fillStyle = 'lightgray';
    ctx.fill();
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'gray';
    ctx.stroke();

    console.log('finish drawing waveform');
}

export default function WaveformViewer(props: Props) {
    const { audio, ...canvasProps } = props;
    const requestAudioSummary = useAppState(state => state.actions.requestAudioSummary);
    const audioSummary = useAppState(state => state.values.latencyRecordingSummary);
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [width, setWidth] = useState<number | null>(null);
    const [height, setHeight] = useState<number | null>(null);

    // Request audio summary whenever audio is updated
    useEffect(() => {
        if (width !== null) {
            requestAudioSummary(audio, width);
        }
    }, [audio, width, requestAudioSummary])

    const initCanvas: RefCallback<HTMLCanvasElement> = (ref) => {
        if (ref) {
            const ctx = ref.getContext("2d") || null;
            setWidth(ref.width);
            setHeight(ref.height);
            setCtx(ctx);
        }
    }
    // useLayoutEffect runs *synchronously* after render,
    // but before the screen is updated, whereas useEffect is async.
    // useLayoutEffect for graphics will prevent flickering on render.
    useLayoutEffect(() => {
        if (ctx && width && height) {
            ctx.clearRect(0, 0, width, height);
            if (audioSummary) {
                draw(ctx, audioSummary, height);
            }
        }
    }, [ctx, audioSummary, height, width]);

    return <canvas
        ref={initCanvas}
        {...canvasProps}
    />
}