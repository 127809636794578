import { ReactElement } from "react";
import { useAppState } from "./state";
import RecordingList from "./RecordingList";

function RecordingControls() {
    const nowRecording = useAppState(state => state.values.nowRecording);
    const { startUserRecording, stopUserRecording } = useAppState(state => state.actions);

    let children: ReactElement;

    if (nowRecording) {
        children = (
            <>
                <button onClick={stopUserRecording}>Stop Recording</button>
                <p>Recording in progress...</p>
            </>
        );
    } else {
        children = (
            <>
                <button onClick={startUserRecording}>Start Recording</button>
            </>
        );
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", border: "1px solid gray", padding: "10px", margin: "10px" }} >
            <h3>Recordings</h3>
            {children}
            <RecordingList />
        </div>
    );
}

export default RecordingControls;