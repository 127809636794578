import LatencyTestWidget from "./LatencyTestWidget";
import LoginForm from "./LoginForm";

function LoginScreen() {
    return <div style={{ gridRow: 2, marginTop: "2em" }}>
        <p>Hello! Welcome to this demo. Thanks for being here!</p>
        <p>This is <b>highly experimental</b> software.</p>
        <br />
        <p>Please <b>unplug your headphones</b> and take the test below.</p>
        <p>We need to measure your device's internal <b>audio latency </b>
            (how long it takes to play and record audio).</p>
        <p>You will hear a 5-second audio clip, which will be recorded by your microphone.</p>
        <p>Background noise may interfere with this test.</p>
        <br />
        <LatencyTestWidget />
        <br />
        <p>Your latency should be between 50ms and 600ms.</p>
        <p>If it's way off, try the test again.</p>
        <br />
        <br />
        <p>Once that's done, enter your name and join the call!</p>
        <LoginForm />
    </div>;
}

export default LoginScreen;