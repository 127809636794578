import { UserId } from "wos-types/UserId";
import NameTag from "./NameTag";
import UserVideo from "./UserVideo";
import { useAppState } from "./state";

interface Props {
    stream?: MediaStream;
    peerId: UserId;
}

function PeerVideo(props: Props) {
    const username = useAppState(state => state.values.peers.find(desc => desc.userId === props.peerId)?.username)
    const nametag = username ? <NameTag name={username} /> : <div />;
    return (
        <div className="video-container">
            <UserVideo stream={props.stream} />
            {nametag}
        </div>
    );
}

export default PeerVideo;