import AudioGraphController from "./AudioGraphController";
import LinearStageList from "./LinearStageList";
import MuteCrowdCheckbox from "./MuteCrowdCheckbox";
import StageCheckbox from "./StageCheckbox";
import UserList from "./UserList";

export default function AdminPanel() {

    return <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid black",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "10px",
            paddingBottom: "20px",
            alignItems: "center",
        }}>
            <h2>Admin</h2>
            <div className="row-wrap-container">
                <UserList />
                <AudioGraphController />
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <div style={{ display: "flex" }}>
                    <StageCheckbox />
                    <h3 style={{ marginBottom: "0.5em", marginTop: "0.5em" }}>Stage ⬇️</h3>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>shhh!</p>
                    <MuteCrowdCheckbox />
                </div>
                <LinearStageList />
            </div>
        </div>
    </div>
}