import MyVideo from "./MyVideo";
import PeerVideo from "./PeerVideo";
import { useAppState } from "./state";

export default function VideoGallery() {
    const userId = useAppState(state => state.values.userId);
    const remoteMediaStreams = useAppState(state => state.values.remoteMediaStreams);
    const peers = useAppState(state => state.values.peers);
    const mediaStream = useAppState(state => state.values.mediaStream);

    const peerVideos = peers
        .filter(peer => peer.userId !== userId)
        .map(description => {
            const peerId = description.userId;
            const stream = remoteMediaStreams.get(peerId);
            return (
                <PeerVideo stream={stream} key={peerId} peerId={peerId} />
            );
        });

    return <div className="row-wrap-container"
        style={{
            gridColumn: 1,
            height: "min-content",
        }}  >
        <MyVideo stream={mediaStream} />
        {peerVideos}
    </div>

}